






























import { defineComponent, ref, onMounted, onUnmounted, computed } from '@vue/composition-api';

import CrossCloseButton from './CrossCloseButton.vue';

export default defineComponent({
    name: 'search-bar',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    setup(props, context) {
        const searchBarInput = ref<HTMLInputElement | null>(null);
        const width = ref(1000);
        const placeholderText = ref('');
        const isInputFocused = ref(false);

        let observer: ResizeObserver;

        const getFontSize = (element: HTMLElement) => {
            const style = getComputedStyle(element);
            const fontSize = style.getPropertyValue('--body-size');
            return fontSize;
        };

        const getTextWidth = (text: string, size: string) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) return 0;
            ctx.font = size;
            return ctx.measureText(text).width;
        };

        const maxWidth = computed(() => {
            if (!searchBarInput.value) return '100%';
            const fontSize = getFontSize(searchBarInput.value);
            const textSize = getTextWidth(placeholderText.value, fontSize);
            return isInputActive.value ? '100%' : `${textSize + 120}px`;
        });

        const handleFocus = () => {
            isInputFocused.value = true;
        };

        const handleBlur = () => {
            isInputFocused.value = false;
        };

        const inputValue = computed(() => props.value);

        const isInputActive = computed(() => {
            // Only collapse if not focused and no text
            if (!isInputFocused.value && inputValue.value === '') return false;
            return true;
        });

        const handleClose = (e: Event) => {
            context.emit('input', '');
            // Prevent the search bar from losing or gaining focus when not intended
            e.stopImmediatePropagation();
        };

        onMounted(() => {
            if ('ResizeObserver' in window && searchBarInput.value) {
                observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                    const entry = entries[0];
                    width.value = entry.contentBoxSize
                        ? ('length' in entry.contentBoxSize
                            ? entry.contentBoxSize[0].inlineSize
                            : (entry.contentBoxSize as any).inlineSize)
                        : entry.contentRect.width;

                    placeholderText.value = (width.value < 50
                        ? ''
                        : width.value > 340
                            ? context.root.$t('Search transactions by contact, address, etc.')
                            : width.value > 130
                                ? context.root.$t('Search transactions')
                                : context.root.$t('Search')) as string;
                });
                observer.observe(searchBarInput.value);
            }
        });

        onUnmounted(() => {
            if (observer && searchBarInput.value) {
                observer.unobserve(searchBarInput.value);
            }
        });

        return {
            searchBarInput,
            width,
            maxWidth,
            placeholderText,
            isInputActive,
            handleFocus,
            handleBlur,
            handleClose,
        };
    },
    components: {
        CrossCloseButton,
    },
});
